import { defineStore } from "pinia";
import { ref } from "vue";
// import { getCmsHomePage as HHHTT } from "~~/api/strapi-cms/homepage";
import { CMS_ENV } from "~~/constants/env";
import { useCMSURLs } from "~~/constants/cms.urls";
import { GetFromRedis, getRedis, setRedis, SetToRedis } from "../../api/redis";
import { useUserStore } from "~~/store/UserStore";
import nuxtStorage from "nuxt-storage";

export const useCmsStore = defineStore("cms", () => {

  const sliderImages = ref([]);
  const categoryImages = ref([]);
  const producTypeImages = ref([]);
  const brandImages = ref([]);
  const searchImages = ref([]);
  const licenseImages = ref([]);

  // main cover shows sliding images
  const coverSlider = ref([]);
  const cover = ref({});
  const chainCovers = ref([]);
  const CMSURL = useCMSURLs[CMS_ENV].PATH;
  const accessToken = useCMSURLs[CMS_ENV].PUBLIC_API_TOKEN;

  const userStore = useUserStore();
  const cldUser = computed(() => userStore.cldUser);

  const setUpdatedStates = async () => {
    await SetToRedis("cms-nuxt-all", JSON.stringify({
      sliderImages: sliderImages.value,
      categoryImages: categoryImages.value,
      producTypeImages: producTypeImages.value,
      brandImages: brandImages.value,
      searchImages: searchImages.value,
      licenseImages: licenseImages.value,
      coverSlider: coverSlider.value,
      cover: cover.value,
      chainCovers: chainCovers.value,
    }));
  }
  onMounted(() => {
    setTimeout(() => {
      setUpdatedStates().then(() => { console.info("updated cms store") });
    }, 1000 * 20);
  });

  const initFromCache = async () => {
    const fromCache = await GetFromRedis("cms-nuxt-all");
    if (fromCache.value) {
      const cachedData = JSON.parse(fromCache.value);
      sliderImages.value = cachedData.sliderImages;
      categoryImages.value = cachedData.categoryImages;
      producTypeImages.value = cachedData.producTypeImages;
      brandImages.value = cachedData.brandImages;
      searchImages.value = cachedData.searchImages;
      licenseImages.value = cachedData.licenseImages;
      coverSlider.value = cachedData.coverSlider;
      cover.value = cachedData.cover;
      chainCovers.value = cachedData.chainCovers;
      // console.info("initFromCache", cachedData);

    }
    return true;
  }

  const getHomePage = async (selectedLanguage = "en_GB") => {
    const { data } = await getCmsHomePage(selectedLanguage.replace("_", "-"));

    const { attributes } = data.value.data;

    cover.value = {
      ...attributes.cover,
      image: attributes.cover.image?.data?.attributes,
    };

    producTypeImages.value = attributes.productype.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
    }));

    chainCovers.value = attributes.chain_cover.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
      chainId: x.chain,
    }));


    if (
      cldUser.value &&
      cldUser.value.Chain &&
      chainCovers.value &&
      chainCovers.value.length
    ) {
      const cc = chainCovers.value.find(
        (x) => x.chainId === cldUser.value.Chain
      );
      if (cc) {
        cover.value = { image: cc.image, ...cc };
      }
    }

  };

  //#region HTTP FUNC

  const getCmsHomePage = async (locale) => {

    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=productype.image,brand.image,chain_cover.image,cover.image,license.image`; //&locale=en-GB&locale=${locale}`;
    // console.log("url", url);
    const { data, error } = await useFetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${accessToken}`,
      },
    });
    // console.log("error", error.value, data.value);
    return { data };
    // return { data: data.value.data };
  };
  const getCmsCategory = async () => {
    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=category.image`; //&locale=en-GB&locale=${locale}`;
    // console.log("url", url);
    const { data, error } = await useFetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${accessToken}`,
      },
    });
    const { attributes } = data.value.data;

    categoryImages.value = attributes.category.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
      url: x.url,
    }));

  };

  const getCmsBrand = async () => {

    const _brandImages = nuxtStorage.localStorage.getData("BrandCms");
    if (_brandImages) {
      brandImages.value = JSON.parse(_brandImages);
      return;
    }
    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=brand.image`; //&locale=en-GB&locale=${locale}`;

    const { data, error } = await useFetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${accessToken}`,
      },
    });
    const { attributes } = data.value.data;

    brandImages.value = attributes.brand.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
      url: x.url,
    }));

  };


  const getCmsSlider = async () => {
    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=swiper.image`; //&locale=en-GB&locale=${locale}`;
    const { data, error } = await useFetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${accessToken}`,
      },
    });
    const { attributes } = data.value.data;

    sliderImages.value = attributes.swiper
      .map((x) => ({
        image: (x.image.data && x.image.data.attributes) || null,
        slug: x.slug,
        id: x.id,
        url: x.url,
      }))
      .filter((x) => x.image);

  };

  const getCoverSliders = async (selectedLanguage = "en_GB") => {

    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=cover_slider.image`;
    const { data } = await getCmsResource({
      locale: selectedLanguage.replace("_", "-"),
      url: url,
    });

    const { attributes } = data.value.data;
    coverSlider.value = attributes.cover_slider.map((x) => ({
      image: x.image.data && x.image.data.attributes,
      url: x.url,
      slug: x.slug,
    }));

  };
  const getLicense = async (selectedLanguage = "en_GB") => {
    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=license.image`; //&locale=en-GB&locale=${locale}`;
    const { data } = await getCmsResource({
      locale: selectedLanguage.replace("_", "-"),
      url: url,
    });

    const { attributes } = data.value.data;
    licenseImages.value = attributes.license.map((x) => ({
      image: x.image.data && x.image.data.attributes,
      url: x.url,
      slug: x.slug,
    }));
  };
  const getCmsResource = async ({ locale, url }) => {
    const { data, error } = await useFetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${accessToken}`,
      },
    });
    return { data };
  };

  //#endregion
  const getImageUrl = (url) => `${CMSURL}${url}`;
  return {
    getHomePage,
    getLicense,
    getImageUrl,
    getCoverSliders,
    getCmsCategory,
    getCmsBrand,
    getCmsSlider,
    sliderImages,
    searchImages,
    categoryImages,
    producTypeImages,
    brandImages,
    cover,
    initFromCache,
    licenseImages,
    coverSlider,
  };
}, {
  persist: {
    storage: process.client ? sessionStorage : null,
    beforeRestore: (ctx) => {
      if (process.server) {
        ctx.store.$state = {} // Initialize with empty state on server
      }
    },
    debug: process.dev,
  },
  devtools: true,
});
