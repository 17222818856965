const CMS_PATH_PROD = "https://cms.cld.eu";
const CMS_PATH_LOCAL_DEV = "http://localhost:1337";

export const useCMSURLs = Object.freeze({
  production: {
    PATH: CMS_PATH_PROD,
    PUBLIC_API_TOKEN:
      "3e403d145789b5e5c5030b07ab354b4fbe3addd12effbca4359bf2ef5c3cd0bc6992a0687b0d492ec5a1e8f82aabb7db4d1b00d02c55c19cb5d8ee9dba3e931b6fd2063aa2b044ccb0ca99d91bcf4469382db0aadb425d3c5530a58173a6f5b9385bdd4ecb862058560c764a3b7185444e3e59115203274f7b52ce7c180f0516",
    HOME_PAGE: `${CMS_PATH_PROD}/api/nuxt-home`,
  },
  development: {
    PATH: CMS_PATH_LOCAL_DEV,
    PUBLIC_API_TOKEN:
      "676977478958ed3a0b0eb260fdad5531e6dc669cbe457b73018e632b7e3cb9235a61ef1d4742bdcedbe84c0aeb1868cad64cb8894ae37717cef1cda01deaa1aea69605b6b518a2e4613e2c29c0b1e89c3c8d3d1c2d4cd420c469a62b71033b212c5144e5f5a70704d19af05fc90f3fea490424dcf02be7e670e51ea306dcd2b8",
    HOME_PAGE: `${CMS_PATH_PROD}/api/nuxt-home`,
  },
});
